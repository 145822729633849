import { Injectable } from '@angular/core';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword, onAuthStateChanged,
  sendPasswordResetEmail,
  signOut
} from "firebase/auth";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public uid;
  private auth = getAuth();
  private idToken;

  constructor() {

  }

  authState() {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(this.auth, (user) => {

        // //console.log(this.auth.currentUser);

        if (this.auth.currentUser) {
          this.uid = this.auth.currentUser.uid;
          resolve(this.auth.currentUser.uid);

        } else {

          reject(null)

        }
      });
    })
  }

  createUserWithEmailAndPassword(email, password): Promise<any> {
    return createUserWithEmailAndPassword(this.auth, email, password)
  }

  signInWithEmailAndPassword(email, password): Promise<any> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  sendPasswordResetEmail(email): Promise<any> {
    return sendPasswordResetEmail(this.auth, email);
  }

  signOut(): Promise<any> {
    return signOut(this.auth);
  }

  setIdToken(val){
    this.idToken = val;
    environment.apiKey = val; 
  }

  getIdToken(){
    return this.idToken;
  }


}
