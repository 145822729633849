import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.baseUrl;
  private user: BehaviorSubject<any>;
  private headers = new HttpHeaders({ 'Authorization': environment.apiKey })

  constructor(
    private http: HttpClient
  ) {
    this.user = new BehaviorSubject(null);
  }

  createUser(user): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/users`, user, {
      headers: this.headers
    }).toPromise()
  }

  updateUser(id,user): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}/users/${id}`, user, {
      headers: this.headers
    }).toPromise()
  }

  getUser(email): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}/users`,{
      headers: this.headers,
      params : new HttpParams().set('emailAddress', email)
    }).toPromise()
  }

  getAllUsers(param): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}/users`,{
      headers: this.headers,
      params : new HttpParams().set('role', param)
    }).toPromise()
  }

  getUserById(id): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}/users/${id}`,{
      headers: this.headers
    }).toPromise()
  }

  updateUserObservable(newValue): void {
    this.user.next(newValue);
  }

  getUserObservable(): Observable<any> {
    return this.user.asObservable();
  }
}
