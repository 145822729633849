import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { CryptojsService } from '../services/cryptojs/cryptojs.service';
import { UserService } from '../services/user/user.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authService: AuthService,
    private _router: Router,
    private cryptoJsSrvc: CryptojsService,
    private userSrvc: UserService,
    private navCtrl: NavController,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
    return this.authService.authState().then(
      resp => {

        let user = JSON.parse(this.cryptoJsSrvc.decrypt(localStorage.getItem(resp.toString())));
        this.userSrvc.updateUserObservable(user)

        if(state.url === "/admin" && user.role != "ADMIN"){
          this.navCtrl.navigateForward('home');
          return true;
        }
       
        return true;

      }
    ).catch(error => {
      if(state.url === "/dashboard"){
        this.navCtrl.navigateForward('home');
        return true;
      }
      return true;
    });
  }
}
